import React, { Component } from "react";
import { Typography, Box, Paper, Container } from "@mui/material";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "",
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_APP_VERSION}`)
      .then((res) => res.text())
      .then((data) => {
        this.setState({
          version: data,
        });
      });
  }

  render() {
    const { version } = this.state;
    const { REACT_APP_VERSION_FRONTEND } = process.env;
    return (
      <Paper
        sx={{
          marginTop: "calc(10% + 60px)",
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
        elevation={0}
      >
        <Container>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              mb: 2,
            }}
          >
            <Typography variant="caption" color="grey">
              Cityweb-eservices {REACT_APP_VERSION_FRONTEND} frontend {version}{" "}
              backend
            </Typography>
          </Box>
        </Container>
      </Paper>
    );
  }
}
