import React from "react";
import { Grid, Typography } from "@mui/material";
import InputField from "../../../FormFields/InputField";
import SelectField from "../../../FormFields/SelectField";
import { useFormikContext } from "formik";
import {
  Motif,
  QualiteDemandeur,
} from "../../FormModel/ActeDeNaissance/selectFieldModel";
import {
  ListeMotif,
  ListeQualiteDemandeur,
} from "../../FormModel/ActeDeNaissance/selectFieldValues";
import {
  ListeNatureDocument,
  ListeNbExemplaire,
} from "../../FormModel/selectFieldValues";

function _renderMotifAutre(props) {
  const { values } = useFormikContext();
  const {
    formField: { motifAutre },
  } = props;
  if (values.motif === Motif.autre.value) {
    return (
      <Grid item xs={12} sm={10} md={7}>
        <InputField
          sx={{
            "label::after": {
              content: '"* "',
              color: "red",
            },
          }}
          name={motifAutre.name}
          label={motifAutre.label}
          fullWidth
          disabled={values.motif !== Motif.autre.value}
          data-testid="motif-autre"
        />
      </Grid>
    );
  }
}

function _renderQualiteDemandeurAutre(props) {
  const { values } = useFormikContext();
  const {
    formField: { qualiteDemandeurAutre },
  } = props;
  if (values.qualiteDemandeur === QualiteDemandeur.autre.value) {
    return (
      <Grid item xs={12} sm={10} md={7}>
        <InputField
          sx={{
            "label::after": {
              content: '"* "',
              color: "red",
            },
          }}
          name={qualiteDemandeurAutre.name}
          label={qualiteDemandeurAutre.label}
          fullWidth
          disabled={values.qualiteDemandeur !== QualiteDemandeur.autre.value}
          data-testid="qualite-demandeur-autre"
        />
      </Grid>
    );
  }
}

function _passFormValidation() {
  const { values } = useFormikContext();
  if (values.qualiteDemandeur !== QualiteDemandeur.autre.value) {
    values.qualiteDemandeurAutre = "N/A";
  } else if (values.qualiteDemandeurAutre === "N/A") {
    values.qualiteDemandeurAutre = "";
  }
  if (values.motif !== Motif.autre.value) {
    values.motifAutre = "N/A";
  } else if (values.motifAutre === "N/A") {
    values.motifAutre = "";
  }
}

export default function Demande(props) {
  const {
    formField: {
      motif,
      natureDocument,
      commentaire,
      qualiteDemandeur,
      nbExemplaire,
    },
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={10} md={7}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Votre demande
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Veuillez préciser l'objet de votre demande.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <SelectField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={motif.name}
            label={motif.label}
            data={ListeMotif}
            fullWidth
            data-testid="motif"
          />
        </Grid>
        {_renderMotifAutre(props)}
        <Grid item xs={12} sm={10} md={7}>
          <SelectField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={natureDocument.name}
            label={natureDocument.label}
            data={ListeNatureDocument}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            name={commentaire.name}
            label={commentaire.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <SelectField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={qualiteDemandeur.name}
            label={qualiteDemandeur.label}
            data={ListeQualiteDemandeur}
            fullWidth
            data-testid="qualite-demandeur"
          />
        </Grid>
        {_renderQualiteDemandeurAutre(props)}
        <Grid item xs={12} sm={10} md={7}>
          <SelectField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nbExemplaire.name}
            label={nbExemplaire.label}
            data={ListeNbExemplaire}
            fullWidth
          />
        </Grid>
        {_passFormValidation()}
      </Grid>
    </React.Fragment>
  );
}
