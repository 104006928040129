import * as Yup from "yup";
import formModel from "../ActeDeNaissance/formModel";

const {
  formField: {
    motif,
    motifAutre,
    natureDocument,
    commentaire,
    qualiteDemandeur,
    qualiteDemandeurAutre,
    nbExemplaire,
    nomDeFamilleInteresse,
    prenomsInteresse,
    dateDebut,
    nomDeFamillePere,
    prenomsPere,
    nomDeFamilleMere,
    prenomsMere,
    genre,
    nomDeFamilleDemandeur,
    prenomsDemandeur,
    ligneAdr1,
    codePostal,
    villeDemandeur,
    pays,
    mail,
    tel,
    isTermsOfServiceChecked,
    recaptchaV2,
  },
} = formModel;

const phoneRegExp = /^[0-9+()-. ]+$/;

const validationSchema = [
  Yup.object().shape({
    [motif.name]: Yup.string().required(`${motif.requiredErrorMsg}`),
    [motifAutre.name]: Yup.string().required(`${motifAutre.requiredErrorMsg}`),
    [natureDocument.name]: Yup.string().required(
      `${natureDocument.requiredErrorMsg}`
    ),
    [commentaire.name]: Yup.string()
      .max(100, `${commentaire.maxLenghtErrorMsg}`)
      .trim(),
    [qualiteDemandeur.name]: Yup.string().required(
      `${qualiteDemandeur.requiredErrorMsg}`
    ),
    [qualiteDemandeurAutre.name]: Yup.string().required(
      `${qualiteDemandeurAutre.requiredErrorMsg}`
    ),
    [nbExemplaire.name]: Yup.string().required(
      `${nbExemplaire.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [nomDeFamilleInteresse.name]: Yup.string()
      .required(`${nomDeFamilleInteresse.requiredErrorMsg}`)
      .max(50, `${nomDeFamilleInteresse.maxLenghtErrorMsg}`)
      .trim(),
    [prenomsInteresse.name]: Yup.string()
      .required(`${prenomsInteresse.requiredErrorMsg}`)
      .max(50, `${prenomsInteresse.maxLenghtErrorMsg}`)
      .trim(),
    [dateDebut.name]: Yup.string()
      .nullable()
      .required(`${dateDebut.requiredErrorMsg}`),
    [nomDeFamillePere.name]: Yup.string()
      .required(`${nomDeFamillePere.requiredErrorMsg}`)
      .max(50, `${nomDeFamillePere.maxLenghtErrorMsg}`)
      .trim(),
    [prenomsPere.name]: Yup.string()
      .required(`${prenomsPere.requiredErrorMsg}`)
      .max(50, `${prenomsPere.maxLenghtErrorMsg}`)
      .trim(),
    [nomDeFamilleMere.name]: Yup.string()
      .required(`${nomDeFamilleMere.requiredErrorMsg}`)
      .max(50, `${nomDeFamilleMere.maxLenghtErrorMsg}`)
      .trim(),
    [prenomsMere.name]: Yup.string()
      .required(`${prenomsMere.requiredErrorMsg}`)
      .max(50, `${prenomsMere.maxLenghtErrorMsg}`)
      .trim(),
  }),
  Yup.object().shape({
    [genre.name]: Yup.string().required(`${genre.requiredErrorMsg}`),
    [nomDeFamilleDemandeur.name]: Yup.string()
      .required(`${nomDeFamilleDemandeur.requiredErrorMsg}`)
      .max(50, `${nomDeFamilleDemandeur.maxLenghtErrorMsg}`)
      .trim(),
    [prenomsDemandeur.name]: Yup.string()
      .required(`${prenomsDemandeur.requiredErrorMsg}`)
      .max(50, `${prenomsDemandeur.maxLenghtErrorMsg}`)
      .trim(),
    [ligneAdr1.name]: Yup.string()
      .required(`${ligneAdr1.requiredErrorMsg}`)
      .max(46, `${ligneAdr1.maxLenghtErrorMsg}`)
      .trim(),
    [codePostal.name]: Yup.string()
      .required(`${codePostal.requiredErrorMsg}`)
      .max(16, `${codePostal.maxLenghtErrorMsg}`)
      .trim(),
    [villeDemandeur.name]: Yup.string()
      .required(`${villeDemandeur.requiredErrorMsg}`)
      .max(28, `${villeDemandeur.maxLenghtErrorMsg}`)
      .trim(),
    [pays.name]: Yup.string().required(`${pays.requiredErrorMsg}`),
    [mail.name]: Yup.string()
      .required(`${mail.requiredErrorMsg}`)
      .email("Email invalide")
      .max(256, `${mail.maxLenghtErrorMsg}`)
      .trim(),
    [tel.name]: Yup.string()
      .required(`${tel.requiredErrorMsg}`)
      .matches(phoneRegExp, "Le numéro de téléphone n'est pas valide")
      .max(50, `${tel.maxLenghtErrorMsg}`)
      .trim(),
  }),
  Yup.object().shape({
    [isTermsOfServiceChecked.name]: Yup.boolean()
      .required(`${isTermsOfServiceChecked.requiredErrorMsg}`)
      .oneOf([true], `${isTermsOfServiceChecked.requiredErrorMsg}`),
    [recaptchaV2.name]: Yup.string().required(
      `${recaptchaV2.requiredErrorMsg}`
    ),
  }),
];

export default validationSchema;
