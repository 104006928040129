import React, { Component } from "react";
import "../../../App.css";
import AppNavbar from "../../Header/AppNavbar";
import ActeDeMariage from "./ActeDeMariage";

class FormPresentationPage extends Component {
  render() {
    return (
      <div>
        <AppNavbar />
        <ActeDeMariage />
      </div>
    );
  }
}
export default FormPresentationPage;
