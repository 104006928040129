import { Pays, Ville } from "../selectFieldModel";
import formModel from "./formModel";
const {
  formField: {
    natureEvenement,
    origine,
    motif,
    motifAutre,
    natureDocument,
    commentaire,
    qualiteDemandeur,
    qualiteDemandeurAutre,
    nbExemplaire,
    nomDeFamilleInteresse,
    prenomsInteresse,
    dateDebut,
    villeInteresse,
    isUnknownFatherChecked,
    nomDeFamillePere,
    prenomsPere,
    isUnknownMotherChecked,
    nomDeFamilleMere,
    prenomsMere,
    genre,
    nomDeFamilleDemandeur,
    prenomsDemandeur,
    ligneAdr1,
    codePostal,
    villeDemandeur,
    pays,
    mail,
    tel,
    isTermsOfServiceChecked,
    recaptchaV2,
  },
} = formModel;

const formInitialValues = {
  [natureEvenement.name]: "NAI",
  [origine.name]: "internet",
  [motif.name]: "",
  [motifAutre.name]: "",
  [natureDocument.name]: "",
  [commentaire.name]: "",
  [qualiteDemandeur.name]: "",
  [qualiteDemandeurAutre.name]: "",
  [nbExemplaire.name]: "",
  [nomDeFamilleInteresse.name]: "",
  [prenomsInteresse.name]: "",
  [dateDebut.name]: "",
  [villeInteresse.name]: Ville.noumea.value,
  [isUnknownFatherChecked.name]: false,
  [nomDeFamillePere.name]: "",
  [prenomsPere.name]: "",
  [isUnknownMotherChecked.name]: false,
  [nomDeFamilleMere.name]: "",
  [prenomsMere.name]: "",
  [genre.name]: "",
  [nomDeFamilleDemandeur.name]: "",
  [prenomsDemandeur.name]: "",
  [ligneAdr1.name]: "",
  [codePostal.name]: "",
  [villeDemandeur.name]: "",
  [pays.name]: Pays.NC.value,
  [mail.name]: "",
  [tel.name]: "",
  [isTermsOfServiceChecked.name]: false,
  [recaptchaV2.name]: "",
};

export default formInitialValues;
