import { Button, Grid, Typography, Box, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import NoticeMessage from "../../Notice/NoticeMessage";

export default function ActeDeNaissance() {
  const navigate = useNavigate();
  const handleClick = () => navigate("/demande-acte-de-naissance/nouveau");

  return (
    <React.Fragment>
      <Grid container sx={{ px: 2 }} spacing={3} justifyContent="center">
        <Grid item xs={12} sm={10} md={7}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Demande d'acte de naissance
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            Madame, Monsieur,
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            Vous allez utiliser le formulaire en ligne de la ville de Nouméa
            pour effectuer une demande d’acte de naissance.
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            La Ville détient uniquement les actes des naissances qui ont eu lieu
            sur la commune.
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            Les actes de naissance de droit coutumier avant 1968 sont détenus
            par la Direction de la Gestion et de la Réglementation des Affaires
            Coutumières de la Nouvelle-Calédonie.
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            <Link href="https://www.noumea.nc/noumea-pratique/mes-demarches/demande-acte-naissance-droit-commun-droit-coutumier">
              Demande d'acte de naissance de droit commun ou de droit coutumier
              | Ville de Nouméa (noumea.nc)
            </Link>
            .
          </Typography>
          <Grid item xs={12}>
            <NoticeMessage/>
            <Typography
              sx={{ mt: 2 }}
              variant="body2"
              color="text.secondary"
              gutterBottom
            >
              La copie intégrale, l’extrait avec filiation et l’acte plurilingue
              sont délivrables à :
            </Typography>
            <ul className="list">
              <li>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  l’intéressé(e) majeur(e)
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  les ascendants (parents, grands-parents)
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  les descendants (enfants, petits-enfants)
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  l’époux(se)
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  les notaires et avocats.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            L’extrait sans filiation est délivrable à tout requérant.
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            Le document original vous sera envoyé par voie postale, l’envoi d’un
            acte par courrier électronique n’est pas autorisé par le cadre légal
            en vigueur (Instruction Générale Relative à l'Etat Civil, chap.
            514).
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            Les délais d’acheminement du courrier ne peuvent être imputés à la
            ville de Nouméa.
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            gutterBottom
          >
            Toute fausse déclaration est passible des peines d'emprisonnement et
            des amendes prévues par les articles 441-6 et 441-7 du code pénal.
          </Typography>
          <Box textAlign="right">
            <Button
              sx={{ my: 3 }}
              type="button"
              onClick={handleClick}
              variant="contained"
              color="primary"
            >
              Accéder au formulaire en ligne
            </Button>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
