import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import FormPresentationNaissance from "./components/Presentation/ActeDeNaissance/FormPresentationPage";
import FormPresentationMariage from "./components/Presentation/ActeDeMariage/FormPresentationPage";
import FormPresentationDeces from "./components/Presentation/ActeDeDeces/FormPresentationPage";
import FormNaissance from "./components/FormPage/Forms/ActeDeNaissance/FormPage";
import FormMariage from "./components/FormPage/Forms/ActeDeMariage/FormPage";
import FormDeces from "./components/FormPage/Forms/ActeDeDeces/FormPage";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/Home/HomePage";
import FormErrorPage from "./components/FormPage/FormError/FormErrorPage";

const theme = createTheme({
  palette: {
    primary: {
      light: "#33B0CC",
      main: "#245272",
      dark: "#2A7997",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Overpass"].join(","),
    h1: {
      fontFamily: "Barlow Condensed",
      fontWeight: 600,
      color: "#245272",
    },
    h2: {
      fontFamily: "Barlow Condensed",
      fontWeight: 600,
      color: "#245272",
    },
    h3: {
      fontFamily: "Barlow Condensed",
      fontWeight: 600,
      color: "#245272",
    },
    h4: {
      fontFamily: "Barlow Condensed",
      fontSize: 42,
      fontWeight: 600,
      color: "#245272",
    },
    h5: {
      fontFamily: "Barlow Condensed",
      fontWeight: 600,
      color: "#245272",
    },
    h6: {
      fontFamily: "Barlow Condensed",
      fontSize: 28,
      fontWeight: 600,
      color: "#245272",
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 15,
    },
    body3: {
      fontSize: 16,
      fontWeight: 500,
      color: "#dc3545",
    },
    body4: {
      fontSize: 15,
      fontWeight: 500
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    ErrorMessage: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" exact={true} element={<HomePage />} />
            <Route
              path="/demande-acte-de-naissance/"
              exact={true}
              element={<FormPresentationNaissance />}
            />
            <Route
              path="/demande-acte-de-naissance/nouveau"
              exact={true}
              element={<FormNaissance />}
            />
            <Route
              path="/demande-acte-de-mariage/"
              exact={true}
              element={<FormPresentationMariage />}
            />
            <Route
              path="/demande-acte-de-mariage/nouveau"
              exact={true}
              element={<FormMariage />}
            />
            <Route
              path="/demande-acte-de-deces/"
              exact={true}
              element={<FormPresentationDeces />}
            />
            <Route
              path="/demande-acte-de-deces/nouveau"
              exact={true}
              element={<FormDeces />}
            />
            <Route
              path="/soumission-du-formulaire/erreur"
              exact={true}
              element={<FormErrorPage />}
            />
          </Routes>
        </Router>
        <Footer />
      </ThemeProvider>
    );
  }
}

export default App;
