import { Motif, QualiteDemandeur } from "./selectFieldModel";

export const ListeMotif = [];
export const ListeQualiteDemandeur = [];

for (const key in Motif) {
  ListeMotif.push({ label: Motif[key].label, value: Motif[key].value });
}

for (const key in QualiteDemandeur) {
  ListeQualiteDemandeur.push({
    label: QualiteDemandeur[key].label,
    value: QualiteDemandeur[key].value,
  });
}
