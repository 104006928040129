export const Motif = {
  mariageOuPacs: {
    value: "Constitution d'un dossier de mariage ou PACS",
    label: "Constitution d'un dossier de mariage ou PACS",
  },
  prestationSocialeOuRetraite: {
    value: "Prestation sociale, retraite",
    label: "Prestation sociale, retraite",
  },
  genealogie: { value: "Généalogie", label: "Généalogie" },
  venteOuSuccession: { value: "Vente, succession", label: "Vente, succession" },
  autre: { value: "Autre motif", label: "Autre motif" },
};

export const QualiteDemandeur = {
  interesse: { value: "L'intéressé(e)", label: "L'intéressé(e)" },
  parentsOuGrandsParents: {
    value: "Parents, grands-parents",
    label: "Parents, grands-parents",
  },
  enfantsOuPetitsEnfants: {
    value: "Enfants, petits-enfants",
    label: "Enfants, petits-enfants",
  },
  epouxse: { value: "Epoux(se)", label: "Epoux(se)" },
  famille: { value: "Famille", label: "Famille" },
  notaireOuAvocat: { value: "Notaire, avocat", label: "Notaire, avocat" },
  autre: { value: "Autre", label: "Autre" },
};
