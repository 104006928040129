export const NatureDocument = {
  CPI: { value: "CPI", label: "Copie intégrale" },
  EXTAF: { value: "EXTAF", label: "Extrait avec filiation" },
  EXTSF: { value: "EXTSF", label: "Extrait sans filiation" },
  EXTPL: { value: "EXTPL", label: "Extrait plurilingue" },
};

export const NbExemplaire = {
  un: { value: "1", label: "1" },
  deux: { value: "2", label: "2" },
  trois: { value: "3", label: "3" },
};

export const Ville = {
  noumea: { value: "Nouméa", label: "Nouméa" },
  autreVille: { value: "Autre ville", label: "Autre ville" },
};

export const Genre = {
  monsieur: { value: "M.", label: "Monsieur" },
  madame: { value: "Mme", label: "Madame" },
};

export const Pays = {
  AF: { value: "Afghanistan", label: "Afghanistan" },
  ZA: { value: "Afrique du Sud", label: "Afrique du Sud" },
  AL: { value: "Albanie", label: "Albanie" },
  DZ: { value: "Algérie", label: "Algérie" },
  DE: { value: "Allemagne", label: "Allemagne" },
  AD: { value: "Andorre", label: "Andorre" },
  AO: { value: "Angola", label: "Angola" },
  AI: { value: "Anguilla", label: "Anguilla" },
  AG: { value: "Antigua-et-Barbuda", label: "Antigua-et-Barbuda" },
  SA: { value: "Arabie saoudite", label: "Arabie saoudite" },
  AR: { value: "Argentine", label: "Argentine" },
  AM: { value: "Arménie", label: "Arménie" },
  AW: { value: "Aruba", label: "Aruba" },
  AC: { value: "Ascension", label: "Ascension" },
  AU: { value: "Australie", label: "Australie" },
  AT: { value: "Autriche", label: "Autriche" },
  AZ: { value: "Azerbaïdjan", label: "Azerbaïdjan" },
  BS: { value: "Bahamas", label: "Bahamas" },
  BH: { value: "Bahreïn", label: "Bahreïn" },
  BD: { value: "Bangladesh", label: "Bangladesh" },
  BB: { value: "Barbade", label: "Barbade" },
  BE: { value: "Belgique", label: "Belgique" },
  BZ: { value: "Belize", label: "Belize" },
  BJ: { value: "Bénin", label: "Bénin" },
  BM: { value: "Bermudes", label: "Bermudes" },
  BT: { value: "Bhoutan", label: "Bhoutan" },
  BY: { value: "Biélorussie", label: "Biélorussie" },
  BO: { value: "Bolivie", label: "Bolivie" },
  BA: { value: "Bosnie-Herzégovine", label: "Bosnie-Herzégovine" },
  BW: { value: "Botswana", label: "Botswana" },
  BR: { value: "Brésil", label: "Brésil" },
  BN: { value: "Brunei", label: "Brunei" },
  BG: { value: "Bulgarie", label: "Bulgarie" },
  BF: { value: "Burkina Faso", label: "Burkina Faso" },
  BI: { value: "Burundi", label: "Burundi" },
  KH: { value: "Cambodge", label: "Cambodge" },
  CM: { value: "Cameroun", label: "Cameroun" },
  CA: { value: "Canada", label: "Canada" },
  CV: { value: "Cap-Vert", label: "Cap-Vert" },
  EA: { value: "Ceuta et Melilla", label: "Ceuta et Melilla" },
  CL: { value: "Chili", label: "Chili" },
  CN: { value: "Chine", label: "Chine" },
  CY: { value: "Chypre", label: "Chypre" },
  CP: { value: "Clipperton", label: "Clipperton" },
  CO: { value: "Colombie", label: "Colombie" },
  KM: { value: "Comores", label: "Comores" },
  CG: { value: "Congo", label: "Congo" },
  KP: { value: "Corée du Nord", label: "Corée du Nord" },
  KR: { value: "Corée du Sud", label: "Corée du Sud" },
  CR: { value: "Costa Rica", label: "Costa Rica" },
  CI: { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  HR: { value: "Croatie", label: "Croatie" },
  CU: { value: "Cuba", label: "Cuba" },
  CW: { value: "Curaçao", label: "Curaçao" },
  DK: { value: "Danemark", label: "Danemark" },
  DG: { value: "Diego Garcia", label: "Diego Garcia" },
  DJ: { value: "Djibouti", label: "Djibouti" },
  DM: { value: "Dominique", label: "Dominique" },
  EG: { value: "Égypte", label: "Égypte" },
  SV: { value: "El Salvador", label: "El Salvador" },
  AE: { value: "Émirats arabes unis", label: "Émirats arabes unis" },
  EC: { value: "Équateur", label: "Équateur" },
  ER: { value: "Érythrée", label: "Érythrée" },
  ES: { value: "Espagne", label: "Espagne" },
  EE: { value: "Estonie", label: "Estonie" },
  SZ: { value: "Eswatini", label: "Eswatini" },
  US: { value: "États-Unis", label: "États-Unis" },
  ET: { value: "Éthiopie", label: "Éthiopie" },
  FJ: { value: "Fidji", label: "Fidji" },
  FI: { value: "Finlande", label: "Finlande" },
  FR: { value: "France", label: "France" },
  GA: { value: "Gabon", label: "Gabon" },
  GM: { value: "Gambie", label: "Gambie" },
  GE: { value: "Géorgie", label: "Géorgie" },
  GH: { value: "Ghana", label: "Ghana" },
  GI: { value: "Gibraltar", label: "Gibraltar" },
  GR: { value: "Grèce", label: "Grèce" },
  GL: { value: "Groenland", label: "Groenland" },
  GD: { value: "Grenade", label: "Grenade" },
  GP: { value: "Guadeloupe", label: "Guadeloupe" },
  GU: { value: "Guam", label: "Guam" },
  GT: { value: "Guatemala", label: "Guatemala" },
  GG: { value: "Guernesey", label: "Guernesey" },
  GN: { value: "Guinée", label: "Guinée" },
  GW: { value: "Guinée-Bissau", label: "Guinée-Bissau" },
  GQ: { value: "Guinée équatoriale", label: "Guinée équatoriale" },
  GY: { value: "Guyana", label: "Guyana" },
  GF: { value: "Guyane", label: "Guyane" },
  HT: { value: "Haïti", label: "Haïti" },
  HN: { value: "Honduras", label: "Honduras" },
  HK: { value: "Hong Kong", label: "Hong Kong" },
  HU: { value: "Hongrie", label: "Hongrie" },
  BV: { value: "Île Bouvet", label: "Île Bouvet" },
  CX: { value: "Île Christmas", label: "Île Christmas" },
  IM: { value: "Île de Man", label: "Île de Man" },
  NF: { value: "Île Norfolk", label: "Île Norfolk" },
  AX: { value: "Îles Åland", label: "Îles Åland" },
  KY: { value: "Îles Caïmans", label: "Îles Caïmans" },
  IC: { value: "Îles Canaries", label: "Îles Canaries" },
  CC: { value: "Îles Cocos", label: "Îles Cocos" },
  CK: { value: "Îles Cook", label: "Îles Cook" },
  FK: { value: "Îles Falkland", label: "Îles Falkland" },
  FO: { value: "Îles Féroé", label: "Îles Féroé" },
  GS: { value: "Îles Géorgie du Sud et Sandwich du Sud", label: "Îles Géorgie du Sud et Sandwich du Sud" },
  HM: { value: "Îles Heard et McDonald", label: "Îles Heard et McDonald" },
  MP: { value: "Îles Mariannes du Nord", label: "Îles Mariannes du Nord" },
  MH: { value: "Îles Marshall", label: "Îles Marshall" },
  UM: { value: "Îles mineures éloignées des États-Unis", label: "Îles mineures éloignées des États-Unis" },
  PN: { value: "Îles Pitcairn", label: "Îles Pitcairn" },
  SB: { value: "Îles Salomon", label: "Îles Salomon" },
  TC: { value: "Îles Turks-et-Caïcos", label: "Îles Turks-et-Caïcos" },
  VI: { value: "Îles Vierges américaines", label: "Îles Vierges américaines" },
  VG: { value: "Îles Vierges britanniques", label: "Îles Vierges britanniques" },
  IS: { value: "Islande", label: "Islande" },
  IN: { value: "Inde", label: "Inde" },
  ID: { value: "Indonésie", label: "Indonésie" },
  IR: { value: "Iran", label: "Iran" },
  IQ: { value: "Iraq", label: "Iraq" },
  IE: { value: "Irlande", label: "Irlande" },
  IL: { value: "Israël", label: "Israël" },
  IT: { value: "Italie", label: "Italie" },
  JM: { value: "Jamaïque", label: "Jamaïque" },
  JP: { value: "Japon", label: "Japon" },
  JE: { value: "Jersey", label: "Jersey" },
  JO: { value: "Jordanie", label: "Jordanie" },
  KZ: { value: "Kazakhstan", label: "Kazakhstan" },
  KE: { value: "Kenya", label: "Kenya" },
  KG: { value: "Kirghizstan", label: "Kirghizstan" },
  KI: { value: "Kiribati", label: "Kiribati" },
  XK: { value: "Kosovo", label: "Kosovo" },
  KW: { value: "Koweït", label: "Koweït" },
  LA: { value: "Laos", label: "Laos" },
  LS: { value: "Lesotho", label: "Lesotho" },
  LV: { value: "Lettonie", label: "Lettonie" },
  LB: { value: "Liban", label: "Liban" },
  LR: { value: "Liberia", label: "Liberia" },
  LY: { value: "Libye", label: "Libye" },
  LI: { value: "Liechtenstein", label: "Liechtenstein" },
  LT: { value: "Lituanie", label: "Lituanie" },
  LU: { value: "Luxembourg", label: "Luxembourg" },
  MO: { value: "Macao", label: "Macao" },
  MK: { value: "Macédoine du Nord", label: "Macédoine du Nord" },
  MG: { value: "Madagascar", label: "Madagascar" },
  MY: { value: "Malaisie", label: "Malaisie" },
  MW: { value: "Malawi", label: "Malawi" },
  MV: { value: "Maldives", label: "Maldives" },
  ML: { value: "Mali", label: "Mali" },
  MT: { value: "Malte", label: "Malte" },
  MA: { value: "Maroc", label: "Maroc" },
  MQ: { value: "Martinique", label: "Martinique" },
  MU: { value: "Maurice", label: "Maurice" },
  MR: { value: "Mauritanie", label: "Mauritanie" },
  YT: { value: "Mayotte", label: "Mayotte" },
  MX: { value: "Mexico", label: "Mexico" },
  FM: { value: "Micronésie", label: "Micronésie" },
  MD: { value: "Moldavie", label: "Moldavie" },
  MC: { value: "Monaco", label: "Monaco" },
  MN: { value: "Mongolie", label: "Mongolie" },
  ME: { value: "Monténégro", label: "Monténégro" },
  MS: { value: "Montserrat", label: "Montserrat" },
  MZ: { value: "Mozambique", label: "Mozambique" },
  MM: { value: "Myanmar (Birmanie)", label: "Myanmar (Birmanie)" },
  NA: { value: "Namibie", label: "Namibie" },
  NR: { value: "Nauru", label: "Nauru" },
  NP: { value: "Népal", label: "Népal" },
  NL: { value: "Pays-Bas", label: "Pays-Bas" },
  NI: { value: "Nicaragua", label: "Nicaragua" },
  NE: { value: "Niger", label: "Niger" },
  NG: { value: "Nigeria", label: "Nigeria" },
  NU: { value: "Niue", label: "Niue" },
  NO: { value: "Norvège", label: "Norvège" },
  NC: { value: "Nouvelle-Calédonie", label: "Nouvelle-Calédonie" },
  NZ: { value: "Nouvelle-Zélande", label: "Nouvelle-Zélande" },
  OM: { value: "Oman", label: "Oman" },
  UG: { value: "Ouganda", label: "Ouganda" },
  UZ: { value: "Ouzbékistan", label: "Ouzbékistan" },
  PK: { value: "Pakistan", label: "Pakistan" },
  PW: { value: "Palaos", label: "Palaos" },
  PS: { value: "Palestine", label: "Palestine" },
  PA: { value: "Panama", label: "Panama" },
  PG: { value: "Papouasie-Nouvelle-Guinée", label: "Papouasie-Nouvelle-Guinée" },
  PY: { value: "Paraguay", label: "Paraguay" },
  BQ: { value: "Pays-Bas caribéens", label: "Pays-Bas caribéens" },
  PE: { value: "Pérou", label: "Pérou" },
  PH: { value: "Philippines", label: "Philippines" },
  PL: { value: "Pologne", label: "Pologne" },
  PF: { value: "Polynésie française", label: "Polynésie française" },
  PR: { value: "Porto Rico", label: "Porto Rico" },
  PT: { value: "Portugal", label: "Portugal" },
  QA: { value: "Qatar", label: "Qatar" },
  CF: { value: "République centrafricaine", label: "République centrafricaine" },
  CD: { value: "République démocratique du Congo", label: "République démocratique du Congo" },
  DO: { value: "République dominicaine", label: "République dominicaine" },
  RE: { value: "Réunion", label: "Réunion" },
  RO: { value: "Roumanie", label: "Roumanie" },
  GB: { value: "Royaume-Uni", label: "Royaume-Uni" },
  RU: { value: "Russie", label: "Russie" },
  RW: { value: "Rwanda", label: "Rwanda" },
  EH: { value: "Sahara occidental", label: "Sahara occidental" },
  BL: { value: "Saint-Barthélemy", label: "Saint-Barthélemy" },
  KN: { value: "Saint-Christophe-et-Niévès", label: "Saint-Christophe-et-Niévès" },
  SM: { value: "Saint-Marin", label: "Saint-Marin" },
  MF: { value: "Saint-Martin", label: "Saint-Martin" },
  PM: { value: "Saint-Pierre-et-Miquelon", label: "Saint-Pierre-et-Miquelon" },
  VC: { value: "Saint-Vincent-et-les-Grenadines", label: "Saint-Vincent-et-les-Grenadines" },
  SH: { value: "Sainte-Hélène", label: "Sainte-Hélène" },
  LC: { value: "Sainte-Lucie", label: "Sainte-Lucie" },
  WS: { value: "Samoa", label: "Samoa" },
  AS: { value: "Samoa américaines", label: "Samoa américaines" },
  ST: { value: "Sao Tomé-et-Principe", label: "Sao Tomé-et-Principe" },
  SN: { value: "Sénégal", label: "Sénégal" },
  RS: { value: "Serbie", label: "Serbie" },
  SC: { value: "Seychelles", label: "Seychelles" },
  SL: { value: "Sierra Leone", label: "Sierra Leone" },
  SG: { value: "Singapour", label: "Singapour" },
  SX: { value: "Sint-Maarten", label: "Sint-Maarten" },
  SK: { value: "Slovaquie", label: "Slovaquie" },
  SI: { value: "Slovénie", label: "Slovénie" },
  SO: { value: "Somalie", label: "Somalie" },
  SD: { value: "Soudan", label: "Soudan" },
  SS: { value: "Soudan du Sud", label: "Soudan du Sud" },
  LK: { value: "Sri Lanka", label: "Sri Lanka" },
  SE: { value: "Suède", label: "Suède" },
  CH: { value: "Suisse", label: "Suisse" },
  SR: { value: "Suriname", label: "Suriname" },
  SJ: { value: "Svalbard et Jan Mayen", label: "Svalbard et Jan Mayen" },
  SY: { value: "Syrie", label: "Syrie" },
  TJ: { value: "Tadjikistan", label: "Tadjikistan" },
  TW: { value: "Taïwan", label: "Taïwan" },
  TZ: { value: "Tanzanie", label: "Tanzanie" },
  TD: { value: "Tchad", label: "Tchad" },
  CZ: { value: "Tchéquie", label: "Tchéquie" },
  TF: { value: "Terres australes et antarctiques françaises", label: "Terres australes et antarctiques françaises" },
  IO: { value: "Territoire britannique de l'océan Indien", label: "Territoire britannique de l'océan Indien" },
  TH: { value: "Thaïlande", label: "Thaïlande" },
  TL: { value: "Timor-Oriental", label: "Timor-Oriental" },
  TG: { value: "Togo", label: "Togo" },
  TK: { value: "Tokélaou", label: "Tokélaou" },
  TO: { value: "Tonga", label: "Tonga" },
  TT: { value: "Trinité-et-Tobago", label: "Trinité-et-Tobago" },
  TA: { value: "Tristan da Cunha", label: "Tristan da Cunha" },
  TN: { value: "Tunisie", label: "Tunisie" },
  TM: { value: "Turkménistan", label: "Turkménistan" },
  TR: { value: "Turquie", label: "Turquie" },
  TV: { value: "Tuvalu", label: "Tuvalu" },
  UA: { value: "Ukraine", label: "Ukraine" },
  UY: { value: "Uruguay", label: "Uruguay" },
  VU: { value: "Vanuatu", label: "Vanuatu" },
  VA: { value: "Vatican", label: "Vatican" },
  VE: { value: "Venezuela", label: "Venezuela" },
  VN: { value: "Viêt Nam", label: "Viêt Nam" },
  WF: { value: "Wallis-et-Futuna", label: "Wallis-et-Futuna" },
  YE: { value: "Yémen", label: "Yémen" },
  ZM: { value: "Zambie", label: "Zambie" },
  ZW: { value: "Zimbabwe", label: "Zimbabwe" },
};
