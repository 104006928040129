import React from "react";
import AppNavbar from "../../Header/AppNavbar";
import Footer from "../../Footer/Footer";
import FormError from "./FormError";

export default function FormErrorPage(error) {
  return (
    <div>
      <AppNavbar />
      <FormError error={error} />
      <Footer />
    </div>
  );
}
