import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { Grid, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { fr } from "date-fns/locale";
import { format } from "date-fns";

export default function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const dateString = format(date,'yyyy-MM-dd')
        setValue(dateString);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }

  const customFrBtnLabelLocaleText = {
    okButtonLabel: "Ok",
    cancelButtonLabel: "Annuler",
  };

  if ({ matches }) {
    return (
      <Grid container>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={fr}
          localeText={customFrBtnLabelLocaleText}
        >
          <MobileDatePicker
            {...field}
            {...props}
            openTo="year"
            views={["year", "month", "day"]}
            inputFormat="dd/MM/yyyy"
            value={selectedDate}
            onChange={_onChange}
            renderInput={(props) => (
              <TextField
                {...props}
                error={isError}
                helperText={error}
                sx={{
                  "label::after": {
                    content: '"* "',
                    color: "red",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={fr}
          localeText={customFrBtnLabelLocaleText}
        >
          <DatePicker
            {...field}
            {...props}
            openTo="year"
            views={["year", "month", "day"]}
            inputFormat="dd/MM/yyyy"
            value={selectedDate}
            onChange={_onChange}
            renderInput={(props) => (
              <TextField
                {...props}
                error={isError}
                helperText={error}
                sx={{
                  "label::after": {
                    content: '"* "',
                    color: "red",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    );
  }
}
