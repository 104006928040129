import { Box, Grid, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const handleClickDeces = () => navigate("/demande-acte-de-deces/nouveau");
  const handleClickMariage = () => navigate("/demande-acte-de-mariage/nouveau");
  const handleClickNaissance = () =>
    navigate("/demande-acte-de-naissance/nouveau");

  return (
    <React.Fragment>
      <Grid container sx={{ p: 5 }} spacing={3}>
        <Grid item xs={12} sm={10} md={7}>
          <Box sx={{ mt: 2 }}>
            <Link component="button" variant="body2" onClick={handleClickDeces}>
              Accéder au formulaire en ligne de demande d'acte de décès
            </Link>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Link
              component="button"
              variant="body2"
              onClick={handleClickMariage}
            >
              Accéder au formulaire en ligne de demande d'acte de mariage
            </Link>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Link
              component="button"
              variant="body2"
              onClick={handleClickNaissance}
            >
              Accéder au formulaire en ligne de demande d'acte de naissance
            </Link>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
