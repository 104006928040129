import React from "react";
import { Typography, Grid, Button, Box, Link } from "@mui/material";

function FormError() {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={10} md={7}>
        <React.Fragment>
          <Typography sx={{ mt: 5 }} variant="h4" align="center" gutterBottom>
            Nous sommes désolés : (
          </Typography>
          <Typography sx={{ mt: 2 }} variant="h5" align="center">
            Une erreur est survenue. Veuillez réessayer plus tard.
          </Typography>
          <Box textAlign="center">
            <Link
              href="https://www.noumea.nc/noumea-pratique/mes-demarches"
              style={{ textDecoration: "none" }}
            >
              <Button
                sx={{ my: 5 }}
                type="button"
                variant="contained"
                color="primary"
              >
                Retour à mes démarches en ligne
              </Button>
            </Link>
          </Box>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default FormError;
