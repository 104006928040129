import React, { Component } from "react";
import { Typography } from "@mui/material";

export default class NoticeMessage extends Component {
  render() {
    return (
        <Typography
        sx={{ mt: 2 }}
        variant="body4"
        color="text.secondary"
        gutterBottom
        >
         Vous n’avez pas à fournir votre acte de naissance pour une demande de passeport ou de carte nationale d’identité. Il sera transmis directement aux services concernés, et ce, conformément au Décret Etat civil n°2011-167 publié le 10 février 2011 et aux instructions de France Identité (Agence Nationale des Titres Sécurisés)
        </Typography>
    );
  }
}

