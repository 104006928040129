import React, { Component } from "react";
import "../../App.css";
import AppNavbar from "../Header/AppNavbar";
import Home from "./Home";

class HomePage extends Component {
  render() {
    return (
      <div>
        <AppNavbar />
        <Home />
      </div>
    );
  }
}
export default HomePage;
