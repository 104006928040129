import React from "react";
import { Grid, Typography, FormControlLabel, Checkbox } from "@mui/material";
import InputField from "../../../FormFields/InputField";
import SelectField from "../../../FormFields/SelectField";
import DatePickerField from "../../../FormFields/DatePickerField";
import { useFormikContext, Field } from "formik";
import { QualiteDemandeur } from "../../FormModel/ActeDeMariage/selectFieldModel";
import { NatureDocument } from "../../FormModel/selectFieldModel";
import { ListeVille } from "../../FormModel/selectFieldValues";

function _renderUnknownMotherCheckbox(props) {
  const { values } = useFormikContext();
  const {
    formField: { isUnknownMotherChecked },
  } = props;
  if (
    values.natureDocument !== NatureDocument.EXTSF.value &&
    values.qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <Grid item xs={12} sm={10} md={7}>
        <Field
          sx={{ mb: -2 }}
          type="checkbox"
          name={isUnknownMotherChecked.name}
          as={FormControlLabel}
          control={<Checkbox />}
          label={isUnknownMotherChecked.label}
        />
      </Grid>
    );
  }
}

function _renderUnknownFatherCheckbox(props) {
  const { values } = useFormikContext();
  const {
    formField: { isUnknownFatherChecked },
  } = props;
  if (
    values.natureDocument !== NatureDocument.EXTSF.value &&
    values.qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <Grid item xs={12} sm={10} md={7}>
        <Field
          sx={{ mb: -2 }}
          type="checkbox"
          name={isUnknownFatherChecked.name}
          as={FormControlLabel}
          control={<Checkbox />}
          label={isUnknownFatherChecked.label}
        />
      </Grid>
    );
  }
}

function _renderMotherFields(props) {
  const { values } = useFormikContext();
  const {
    formField: { nomDeFamilleMere, prenomsMere },
  } = props;
  if (
    values.isUnknownMotherChecked === false &&
    values.natureDocument !== NatureDocument.EXTSF.value &&
    values.qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nomDeFamilleMere.name}
            label={nomDeFamilleMere.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={prenomsMere.name}
            label={prenomsMere.label}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  }
}

function _renderFatherFields(props) {
  const { values } = useFormikContext();
  const {
    formField: { nomDeFamillePere, prenomsPere },
  } = props;
  if (
    values.isUnknownFatherChecked === false &&
    values.natureDocument !== NatureDocument.EXTSF.value &&
    values.qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nomDeFamillePere.name}
            label={nomDeFamillePere.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={prenomsPere.name}
            label={prenomsPere.label}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  }
}

function _passFormValidationUnknownParent() {
  const { values } = useFormikContext();
  if (
    values.isUnknownFatherChecked === true ||
    values.natureDocument === NatureDocument.EXTSF.value ||
    values.qualiteDemandeur === QualiteDemandeur.notaireOuAvocat.value
  ) {
    values.nomDeFamillePere = "N/A";
    values.prenomsPere = "N/A";
  } else if (values.nomDeFamillePere === "N/A") {
    values.nomDeFamillePere = "";
  } else if (values.prenomsPere === "N/A") {
    values.prenomsPere = "";
  }
  if (
    values.isUnknownMotherChecked === true ||
    values.natureDocument === NatureDocument.EXTSF.value ||
    values.qualiteDemandeur === QualiteDemandeur.notaireOuAvocat.value
  ) {
    values.nomDeFamilleMere = "N/A";
    values.prenomsMere = "N/A";
  } else if (values.nomDeFamilleMere === "N/A") {
    values.nomDeFamilleMere = "";
  } else if (values.prenomsMere === "N/A") {
    values.prenomsMere = "";
  }
}

export default function InteresseMariage(props) {
  const {
    formField: {
      nomDeFamilleInteresse,
      prenomsInteresse,
      dateDebut,
      villeInteresse,
      nomDeFamilleConjoint,
      prenomsConjoint,
    },
  } = props;

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={10} md={7}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Identité de la personne dont vous demandez l'acte
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Veuillez remplir les informations à propos de la personne dont vous
            demandez l'acte.{" "}
            <span style={{ fontWeight: "bold" }}>
              Seuls les actes enregistrés à Nouméa peuvent être délivrés.
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nomDeFamilleInteresse.name}
            label={nomDeFamilleInteresse.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={prenomsInteresse.name}
            label={prenomsInteresse.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <DatePickerField
            name={dateDebut.name}
            label={dateDebut.label}
            format="dd/MM/yy"
            views={["day", "year", "month"]}
            minDate={new Date("1800/01/01")}
            maxDate={new Date()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7} paddingTop={0}>
          <SelectField
            name={villeInteresse.name}
            label={villeInteresse.label}
            data={ListeVille}
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nomDeFamilleConjoint.name}
            label={nomDeFamilleConjoint.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={prenomsConjoint.name}
            label={prenomsConjoint.label}
            fullWidth
          />
        </Grid>
        {_renderUnknownMotherCheckbox(props)}
        {_renderMotherFields(props)}
        {_renderUnknownFatherCheckbox(props)}
        {_renderFatherFields(props)}
        {_passFormValidationUnknownParent()}
      </Grid>
    </React.Fragment>
  );
}
