import React from "react";
import { Typography, Grid, Divider, Chip } from "@mui/material";
import {
  ListeMotif,
  ListeQualiteDemandeur,
} from "../../FormModel/ActeDeMariage/selectFieldValues";
import {
  Motif,
  QualiteDemandeur,
} from "../../FormModel/ActeDeMariage/selectFieldModel";
import {
  ListeGenre,
  ListeNatureDocument,
  ListeNbExemplaire,
  ListePays,
  ListeVille,
} from "../../FormModel/selectFieldValues";
import { NatureDocument } from "../../FormModel/selectFieldModel";

function _renderMotifAutre(props) {
  const { formValues } = props;
  const { motifAutre, motif } = formValues;
  if (motif === Motif.autre.value) {
    return (
      <React.Fragment>
        <Grid>
          <Typography gutterBottom>Si autre motif, préciser</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {motifAutre}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
}

function _renderQualiteDemandeurAutre(props) {
  const { formValues } = props;
  const { qualiteDemandeur, qualiteDemandeurAutre } = formValues;
  if (qualiteDemandeur === QualiteDemandeur.autre.value) {
    return (
      <React.Fragment>
        <Grid>
          <Typography gutterBottom>
            Si autre qualité du demandeur, préciser
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {qualiteDemandeurAutre}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
}

function _renderParentFields(props) {
  const { formValues } = props;
  const {
    isUnknownFatherChecked,
    isUnknownMotherChecked,
    natureDocument,
    qualiteDemandeur,
    nomDeFamillePere,
    prenomsPere,
    nomDeFamilleMere,
    prenomsMere,
  } = formValues;
  if (
    isUnknownFatherChecked === false &&
    isUnknownMotherChecked === false &&
    natureDocument !== NatureDocument.EXTSF.value &&
    qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <React.Fragment>
        <Grid>
          <Typography gutterBottom>Nom du père</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {nomDeFamillePere ? nomDeFamillePere : "(Non renseigné)"}
          </Typography>
        </Grid>
        <Grid>
          <Typography gutterBottom>Prénom du père</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {prenomsPere ? prenomsPere : "(Non renseigné)"}
          </Typography>
        </Grid>
        <Grid>
          <Typography gutterBottom>Nom de naissance de la mère</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {nomDeFamilleMere ? nomDeFamilleMere : "(Non renseigné)"}
          </Typography>
        </Grid>
        <Grid>
          <Typography gutterBottom>Prénom de la mère</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {prenomsMere ? prenomsMere : "(Non renseigné)"}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  } else if (
    isUnknownFatherChecked === false &&
    isUnknownMotherChecked === true &&
    natureDocument !== NatureDocument.EXTSF.value &&
    qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <React.Fragment>
        <Grid>
          <Typography gutterBottom>Nom du père</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {nomDeFamillePere ? nomDeFamillePere : "(Non renseigné)"}
          </Typography>
        </Grid>
        <Grid>
          <Typography gutterBottom>Prénom du père</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {prenomsPere ? prenomsPere : "(Non renseigné)"}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  } else if (
    isUnknownFatherChecked === true &&
    isUnknownMotherChecked === false &&
    natureDocument !== NatureDocument.EXTSF.value &&
    qualiteDemandeur !== QualiteDemandeur.notaireOuAvocat.value
  ) {
    return (
      <React.Fragment>
        <Grid>
          <Typography gutterBottom>Nom de naissance de la mère</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {nomDeFamilleMere ? nomDeFamilleMere : "(Non renseigné)"}
          </Typography>
        </Grid>
        <Grid>
          <Typography gutterBottom>Prénom de la mère</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: 2 }}
            gutterBottom
          >
            {prenomsMere ? prenomsMere : "(Non renseigné)"}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
}

function _findLabelName(list, value) {
  var selectObject = list.find((obj) => {
    return obj.value === value;
  });
  return selectObject.label;
}

function FormValidationDetails(props) {
  const { formValues } = props;
  const {
    motif,
    natureDocument,
    commentaire,
    qualiteDemandeur,
    nbExemplaire,
    nomDeFamilleInteresse,
    prenomsInteresse,
    dateDebut,
    villeInteresse,
    nomDeFamilleConjoint,
    prenomsConjoint,
    genre,
    nomDeFamilleDemandeur,
    prenomsDemandeur,
    ligneAdr1,
    codePostal,
    villeDemandeur,
    pays,
    mail,
    tel,
  } = formValues;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider variant="fullwidth">
          <Chip label="DEMANDE" />
        </Divider>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>
              A quel usage destinez-vous cet acte
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {_findLabelName(ListeMotif, motif)}
            </Typography>
          </Grid>
        </React.Fragment>
        {_renderMotifAutre(props)}
        <React.Fragment>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Quel type de document désirez-vous
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {_findLabelName(ListeNatureDocument, natureDocument)}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Si vous la connaissez, indiquez la référence de l'acte
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {commentaire ? commentaire : "(Non renseigné)"}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={12}>
            <Typography gutterBottom>Qualité du demandeur</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {_findLabelName(ListeQualiteDemandeur, qualiteDemandeur)}
            </Typography>
          </Grid>
        </React.Fragment>
        {_renderQualiteDemandeurAutre(props)}
        <React.Fragment>
          <Grid item xs={12}>
            <Typography gutterBottom>Nombre d'exemplaires souhaités</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {_findLabelName(ListeNbExemplaire, nbExemplaire)}
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullwidth">
          <Chip label="INTERESSE" />
        </Divider>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>
              Nom de l'intéressé(else) - nom de naissance pour les femmes
              mariées
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {nomDeFamilleInteresse}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Prénom(s) de l'intéressé(e)</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {prenomsInteresse}
            </Typography>
          </Grid>
        </React.Fragment>
        {_renderParentFields(props)}
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Nom du conjoint</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {nomDeFamilleConjoint ? nomDeFamilleConjoint : "(Non renseigné)"}
            </Typography>
          </Grid>
          <Grid>
            <Typography gutterBottom>Prénom du conjoint</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {prenomsConjoint ? prenomsConjoint : "(Non renseigné)"}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Date de mariage</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {dateDebut}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Ville de naissance</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {villeInteresse
                ? _findLabelName(ListeVille, villeInteresse)
                : "(Non renseignée)"}
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullwidth">
          <Chip label="COORDONNEES" />
        </Divider>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Civilité</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {_findLabelName(ListeGenre, genre)}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Nom du demandeur</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {nomDeFamilleDemandeur}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Prénoms du demandeur</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {prenomsDemandeur}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Adresse ou BP</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {ligneAdr1}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Code postal</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {codePostal}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Ville</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {villeDemandeur}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Pays</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {_findLabelName(ListePays, pays)}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Email</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {mail}
            </Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid>
            <Typography gutterBottom>Téléphone</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ ml: 2 }}
              gutterBottom
            >
              {tel}
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default FormValidationDetails;
