import React from "react";
import { Typography, Grid, Button, Box, Link } from "@mui/material";

function FormSuccess() {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={10} md={7}>
        <React.Fragment>
          <Typography sx={{ mt: 2 }} variant="h5" align="center" gutterBottom>
            Merci.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="subtitle1" align="center">
            Votre demande est bien enregistrée.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="subtitle1" align="center">
            Elle sera traitée sous 2 jours ouvrables par le service de la Vie
            Citoyenne.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="subtitle1" align="center">
            Pour rappel, l’envoi d’un acte par courrier électronique n’est pas
            autorisé par le cadre légal en vigueur (Instruction Générale
            Relative à l'Etat Civil, chap 514).
          </Typography>
          <Typography sx={{ mt: 2 }} variant="subtitle1" align="center">
            Les délais d’acheminement du courrier ne peuvent être imputés à la
            ville de Nouméa.
          </Typography>
          <Typography sx={{ mt: 2 }} variant="subtitle1" align="center">
            Merci d’avoir utilisé les services en ligne de la mairie de Nouméa.
          </Typography>
          <Box textAlign="center">
            <Link
              href="https://www.noumea.nc/noumea-pratique/mes-demarches"
              style={{ textDecoration: "none" }}
            >
              <Button
                sx={{ my: 3 }}
                type="button"
                variant="contained"
                color="primary"
              >
                Retour à mes démarches en ligne
              </Button>
            </Link>
          </Box>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default FormSuccess;
