import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import { Formik, Form } from "formik";

import Demande from "./Demande";
import Coordonnees from "../Coordonnees";
import Interesse from "./Interesse";
import FormValidation from "../../FormValidation/ActeDeMariage/FormValidationPage";
import FormSuccess from "../../FormSuccess/FormSuccess";

import validationSchema from "../../FormModel/ActeDeMariage/validationSchema";
import formModel from "../../FormModel/ActeDeMariage/formModel";
import formInitialValues from "../../FormModel/ActeDeMariage/initialValues";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppNavbar from "../../../Header/AppNavbar";
import { useNavigate } from "react-router-dom";

const steps = ["Demande", "Intéressé", "Coordonnées", "Validation"];
const { formId, formField } = formModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <Demande formField={formField} />;
    case 1:
      return <Interesse formField={formField} />;
    case 2:
      return <Coordonnees formField={formField} />;
    case 3:
      return <FormValidation formField={formField} />;
    default:
      return <div>404: Not Found</div>;
  }
}

export default function FormPage() {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const hidden = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  async function _submitForm(values, actions) {
    // submit the data to the API
    fetch(
      `${process.env.REACT_APP_API_FORMS}` + (values.id ? "/" + values.id : ""),
      {
        method: "POST",
        headers: {
          Accept: "application/xml; charset=utf-8",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => {
        if (res.status >= 400) {
          throw new Error("Server responds with error!");
        } else {
          actions.setSubmitting(false);
          setActiveStep(activeStep + 1);
        }
      })
      .catch((e) => {
        navigate("/soumission-du-formulaire/erreur");
      });

    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <div>
      <AppNavbar />
      <Grid container sx={{ px: 2, mb: 5 }} justifyContent="center">
        <React.Fragment>
          <Typography component="h1" variant="h4" align="center" sx={{ my: 3 }}>
            Demande d'acte de mariage
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} sx={{ mb: 1 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{hidden ? null : label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
          <React.Fragment>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={8}>
                {activeStep === steps.length ? (
                  <FormSuccess />
                ) : (
                  <Formik
                    initialValues={formInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form id={formId}>
                        {_renderStepContent(activeStep)}
                        <Grid container spacing={3} justifyContent="center">
                          <Grid item xs={12} sm={10} md={7}>
                            <Stack
                              spacing={3}
                              direction="row"
                              sx={{ my: 2 }}
                              justifyContent="flex-end"
                            >
                              {activeStep !== 0 && (
                                <Box textAlign="left">
                                  <Button
                                    onClick={_handleBack}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    Précédent
                                  </Button>
                                </Box>
                              )}
                              <Box textAlign="right">
                                <Button
                                  disabled={isSubmitting}
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  {isLastStep ? "Valider" : "Suivant"}
                                </Button>
                              </Box>
                              {isSubmitting && (
                                <CircularProgress
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                  }}
                                  size={24}
                                />
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        </React.Fragment>
      </Grid>
    </div>
  );
}
