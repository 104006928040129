import React, { Component } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
import logoVDN from "../../img/logo-ville-de-noumea.png";

export default class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <Navbar color="dark" dark expand="xs">
        <NavbarBrand tag={Link} to="">
          <img src={logoVDN} alt="logo" style={{ maxWidth: 140 }} />
        </NavbarBrand>
      </Navbar>
    );
  }
}
