import React, { useState, useRef, useEffect } from "react";
import { useFormikContext, Field, ErrorMessage } from "formik";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import FormValidationDetailsDeces from "../ActeDeDeces/FormValidationDetails";
import Reaptcha from "reaptcha";
import AvocatParagraph from "../AvocatParagraph";

export default function FormValidation(props) {
  const { values: formValues } = useFormikContext();
  const {
    formField: { isTermsOfServiceChecked },
  } = props;
  const [captchaToken, setCaptchaToken] = useState(null);
  const [sitekey, setSitekey] = useState("");
  const captchaRef = useRef(null);
  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
      formValues.recaptchaV2 = res;
    });
  };
  const onExpire = () => {
    formValues.recaptchaV2 = "";
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_SITEKEY}`)
      .then((res) => res.text())
      .then((data) => {
        setSitekey(data);
      });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Récapitulatif de la demande
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Veuillez vérifier l'exactitude des informations ci-dessous avant de
            valider le formulaire.
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormValidationDetailsDeces formValues={formValues} />
          <AvocatParagraph />
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Field
              type="checkbox"
              name={isTermsOfServiceChecked.name}
              as={FormControlLabel}
              control={<Checkbox />}
              label={isTermsOfServiceChecked.label}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <ErrorMessage name={isTermsOfServiceChecked.name}>
              {(msg) => <div style={{ color: "red", fontSize: 14 }}>{msg}</div>}
            </ErrorMessage>
          </Grid>
          <Stack
            spacing={3}
            direction="row"
            sx={{ my: 2 }}
            justifyContent="flex-start"
          >
            {sitekey && (
              <Reaptcha
                sitekey={sitekey}
                ref={captchaRef}
                onVerify={verify}
                onExpire={onExpire}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
