import React from "react";
import { Grid, Typography } from "@mui/material";
import InputField from "../../FormFields/InputField";
import SelectField from "../../FormFields/SelectField";
import { ListeGenre, ListePays } from "../FormModel/selectFieldValues";

export default function Demandeur(props) {
  const {
    formField: {
      genre,
      nomDeFamilleDemandeur,
      prenomsDemandeur,
      ligneAdr1,
      codePostal,
      villeDemandeur,
      pays,
      mail,
      tel,
    },
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={10} md={7}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Vos coordonnées
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Veuillez remplir les coordonnées du demandeur. Le demandeur d'un
            acte d'état civil doit être majeur.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <SelectField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={genre.name}
            label={genre.label}
            data={ListeGenre}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nomDeFamilleDemandeur.name}
            label={nomDeFamilleDemandeur.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={prenomsDemandeur.name}
            label={prenomsDemandeur.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={ligneAdr1.name}
            label={ligneAdr1.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={codePostal.name}
            label={codePostal.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={villeDemandeur.name}
            label={villeDemandeur.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <SelectField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={pays.name}
            label={pays.label}
            data={ListePays}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={mail.name}
            label={mail.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={tel.name}
            label={tel.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
