import React from "react";
import { Grid, Typography } from "@mui/material";
import InputField from "../../../FormFields/InputField";
import SelectField from "../../../FormFields/SelectField";
import { ListeVille } from "../../FormModel/selectFieldValues";

import DatePickerField from "../../../FormFields/DatePickerField";

export default function Interesse(props) {
  const {
    formField: {
      nomDeFamilleInteresse,
      prenomsInteresse,
      dateDebut,
      villeInteresse,
    },
  } = props;

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={10} md={7}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Identité de la personne dont vous demandez l'acte
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Veuillez remplir les informations à propos de la personne dont vous
            demandez l'acte.{" "}
            <span style={{ fontWeight: "bold" }}>
              Seuls les actes enregistrés à Nouméa peuvent être délivrés.
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={nomDeFamilleInteresse.name}
            label={nomDeFamilleInteresse.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <InputField
            sx={{
              "label::after": {
                content: '"* "',
                color: "red",
              },
            }}
            name={prenomsInteresse.name}
            label={prenomsInteresse.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7}>
          <DatePickerField
            name={dateDebut.name}
            label={dateDebut.label}
            format="dd/MM/yy"
            views={["day", "year", "month"]}
            minDate={new Date("1800/01/01")}
            maxDate={new Date()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={10} md={7} paddingTop={0}>
          <SelectField
            name={villeInteresse.name}
            label={villeInteresse.label}
            data={ListeVille}
            disabled={true}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
