const formModel = {
  formId: "validationForm",
  formField: {
    natureEvenement: {
      name: "natureEvenement",
      label: "natureEvenement",
    },
    origine: {
      name: "origine",
      label: "origine",
    },
    motif: {
      name: "motif",
      label: "A quel usage destinez-vous cet acte ?",
      requiredErrorMsg:
        "Veuillez sélectionner à quel usage vous destinez cet acte",
    },
    motifAutre: {
      name: "motifAutre",
      label: "Si autre motif, préciser",
      requiredErrorMsg: "Veuillez préciser à quel usage vous destinez cet acte",
    },
    natureDocument: {
      name: "natureDocument",
      label: "Quel type de document désirez-vous ?",
      requiredErrorMsg: "Veuillez sélectionner un type de document",
    },
    commentaire: {
      name: "commentaire",
      label: "Si vous la connaissez, indiquez la référence de l'acte",
      maxLenghtErrorMsg: "La référence ne doit pas dépasser 100 caractères",
    },
    qualiteDemandeur: {
      name: "qualiteDemandeur",
      label: "Qualité du demandeur",
      requiredErrorMsg: "Veuillez sélectionner la qualité du demandeur",
    },
    qualiteDemandeurAutre: {
      name: "qualiteDemandeurAutre",
      label: "Si autre qualité du demandeur, préciser",
      requiredErrorMsg: "Veuillez préciser la qualité du demandeur",
    },
    nbExemplaire: {
      name: "nbExemplaire",
      label: "Nombre d'exemplaires souhaités",
      requiredErrorMsg:
        "Veuillez sélectionner le nombre d'exemplaires souhaités",
    },
    nomDeFamilleInteresse: {
      name: "nomDeFamilleInteresse",
      label: "Nom de l'intéressé(e) - nom de naissance pour les femmes mariées",
      requiredErrorMsg: "Veuillez remplir le nom de l'intéressé(e)",
      maxLenghtErrorMsg:
        "Le nom de famille de l'intéressé(e) ne doit pas dépasser 50 caractères",
    },
    prenomsInteresse: {
      name: "prenomsInteresse",
      label: "Prénom(s) de l'intéressé(e)",
      requiredErrorMsg: "Veuillez remplir le prénom de l'intéressé(e)",
      maxLenghtErrorMsg:
        "Le(s) prénom(s) de l'intéressé(e) ne doit(vent) pas dépasser 50 caractères",
    },
    dateDebut: {
      name: "dateDebut",
      label: "Date de mariage",
      requiredErrorMsg:
        "Veuillez ajouter la date de mariage du titulaire de l'acte",
    },
    villeInteresse: {
      name: "villeInteresse",
      label: "Ville dans laquelle a eu lieu le mariage",
    },
    isUnknownFatherChecked: {
      name: "isUnknownFatherChecked",
      label: "Père inconnu",
    },
    nomDeFamillePere: {
      name: "nomDeFamillePere",
      label: "Nom du père",
      requiredErrorMsg: "Veuillez remplir le nom du père",
      maxLenghtErrorMsg:
        "Le nom de famille du père ne doit pas dépasser 50 caractères",
    },
    prenomsPere: {
      name: "prenomsPere",
      label: "Prénom(s) du père",
      requiredErrorMsg: "Veuillez remplir le prénom du père",
      maxLenghtErrorMsg:
        "Le(s) prénom(s) du père ne doit(vent) pas dépasser 50 caractères",
    },
    isUnknownMotherChecked: {
      name: "isUnknownMotherChecked",
      label: "Mère inconnue",
    },
    nomDeFamilleMere: {
      name: "nomDeFamilleMere",
      label: "Nom de naissance de la mère",
      requiredErrorMsg: "Veuillez remplir le nom de naissance de la mère",
      maxLenghtErrorMsg:
        "Le nom de famille de naissance de la mère ne doit pas dépasser 50 caractères",
    },
    prenomsMere: {
      name: "prenomsMere",
      label: "Prénom(s) de la mère",
      requiredErrorMsg: "Veuillez remplir le prénom de la mère",
      maxLenghtErrorMsg:
        "Le(s) prénom(s) de la mère ne doit(vent) pas dépasser 50 caractères",
    },
    nomDeFamilleConjoint: {
      name: "nomDeFamilleConjoint",
      label: "Nom du conjoint",
      requiredErrorMsg: "Veuillez remplir le nom du conjoint",
      maxLenghtErrorMsg:
        "Le nom de famille du conjoint ne doit pas dépasser 50 caractères",
    },
    prenomsConjoint: {
      name: "prenomsConjoint",
      label: "Prénom(s) du conjoint",
      requiredErrorMsg: "Veuillez remplir le prénom du conjoint",
      maxLenghtErrorMsg:
        "Le(s) prénom(s) du conjoint ne doit(vent) pas dépasser 50 caractères",
    },
    genre: {
      name: "genre",
      label: "Civilité",
      requiredErrorMsg: "Veuillez sélectionner la civilité du demandeur",
    },
    nomDeFamilleDemandeur: {
      name: "nomDeFamilleDemandeur",
      label: "Nom du demandeur",
      requiredErrorMsg: "Veuillez remplir le nom du demandeur",
      maxLenghtErrorMsg:
        "Le nom du demandeur ne doit pas dépasser 50 caractères",
    },
    prenomsDemandeur: {
      name: "prenomsDemandeur",
      label: "Prénoms du demandeur",
      requiredErrorMsg: "Veuillez remplir le prénom du demandeur",
      maxLenghtErrorMsg:
        "Le(s) prénom(s) du demandeur ne doit(vent) pas dépasser 50 caractères",
    },
    ligneAdr1: {
      name: "ligneAdr1",
      label: "Adresse ou BP",
      requiredErrorMsg: "Veuillez remplir l'adresse ou la BP du demandeur",
      maxLenghtErrorMsg: "L'adresse ou la BP ne doit pas dépasser 46 caractères",
    },
    codePostal: {
      name: "codePostal",
      label: "Code postal",
      requiredErrorMsg: "Veuillez remplir le code postal du demandeur",
      maxLenghtErrorMsg: "Le code postal ne doit pas dépasser 16 caractères",
    },
    villeDemandeur: {
      name: "villeDemandeur",
      label: "Ville",
      requiredErrorMsg: "Veuillez remplir la ville du demandeur",
      maxLenghtErrorMsg: "La ville ne doit pas dépasser 28 caractères",
    },
    pays: {
      name: "pays",
      label: "Pays",
      requiredErrorMsg: "Veuillez sélectionner le pays du demandeur",
    },
    mail: {
      name: "mail",
      label: "Email",
      requiredErrorMsg: "Veuillez remplir l'adresse email du demandeur",
      maxLenghtErrorMsg: "L'adresse email ne doit pas dépasser 256 caractères",
    },
    tel: {
      name: "tel",
      label: "Téléphone",
      requiredErrorMsg: "Veuillez remplir le numéro de téléphone du demandeur",
      maxLenghtErrorMsg:
        "Le numéro de téléphone ne doit pas dépasser 50 caractères",
    },
    isTermsOfServiceChecked: {
      name: "isTermsOfServiceChecked",
      label:
        "En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de cette demande",
      requiredErrorMsg:
        "Vous devez accepter que les informations saisies soient exploitées dans le cadre de cette demande pour soumettre le formulaire",
    },
    recaptchaV2: {
      name: "recaptchaV2",
      label: "recaptcha",
    },
  },
};

export default formModel;
