import {
  Genre,
  NatureDocument,
  NbExemplaire,
  Pays,
  Ville,
} from "./selectFieldModel";

export const ListeNatureDocument = [];
export const ListeNbExemplaire = [];
export const ListeVille = [];
export const ListeGenre = [];
export const ListePays = [];

for (const key in NatureDocument) {
  ListeNatureDocument.push({
    label: NatureDocument[key].label,
    value: NatureDocument[key].value,
  });
}

for (const key in NbExemplaire) {
  ListeNbExemplaire.push({
    label: NbExemplaire[key].label,
    value: NbExemplaire[key].value,
  });
}

for (const key in Ville) {
  ListeVille.push({ label: Ville[key].label, value: Ville[key].value });
}

for (const key in Genre) {
  ListeGenre.push({ label: Genre[key].label, value: Genre[key].value });
}

for (const key in Pays) {
  ListePays.push({ label: Pays[key].label, value: Pays[key].value });
}
