import React from "react";
import { useFormikContext } from "formik";
import { Typography } from "@mui/material";
import { QualiteDemandeur } from "../FormModel/ActeDeDeces/selectFieldModel";

export default function AvocatParagraph() {
  const { values } = useFormikContext();

  if (values.qualiteDemandeur === QualiteDemandeur.notaireOuAvocat.value) {
    return (
      <div>
        <Typography variant="body2" sx={{ mt: 3 }} align="justify">
          Dans le cadre de l’instruction générale relative à l’état-civil (n°
          197-5), je vous précise :
        </Typography>
        <Typography variant="body2" sx={{ ml: 3 }} align="justify">
          - que j’agis en qualité de mandataire de ladite personne, elle-même
          habilitée par la Loi à obtenir un tel document en sa qualité de
          titulaire de l’acte,
        </Typography>
        <Typography variant="body2" sx={{ ml: 3 }} align="justify">
          - que je ne connais pas la filiation de cette personne, mais que cette
          filiation m’est nécessaire pour l’accomplissement de ma mission (Cf
          97-5 IGREC),
        </Typography>
        <Typography variant="body2" align="justify">
          En conséquence, et conformément au décret n° 97-852 du 16 Septembre
          1997 modifiant le décret n° 62-921 du 03 Août 1962, je vous remercie
          de bien vouloir m’adresser une copie intégrale de l’acte demandé.
        </Typography>
      </div>
    );
  }

  return null;
}
